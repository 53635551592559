.assigned-nurse-tag-container{
    display: flex;
    flex-direction: row;
}

.assigned-nurse-tag,
.assigned-nurse-list-tag {
  padding: 0.25rem 0.5rem;
  background-color: var(--modal-background-color);
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  span {
    margin: 0rem 1rem 0 0rem !important;
  }
  svg{
    font-size: 1rem;
    cursor: pointer;
  }
}

.assigned-nurse-list-modal {
  width: 350px !important;
}

.assigned-nurse-list-container {
  width: 100%;
  max-height: 15rem;
  overflow-y: scroll;
  .assigned-nurse-list-tag {
    width: 100% !important;
    height: 2.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 0.85rem;
    padding: 0.25rem 0.75rem;
    svg {
      font-size: 1.25rem;
      margin: 0 0 -0.25rem 0;
    }
  }
}

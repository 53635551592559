.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  width: 100%;
  height: 100%;
}

.ant-spin-dot-item {
  background-color: #00a5b4 !important;
}

.add-status-board-form {
    margin-top: 10px;

    .ant-form-item {
        margin-bottom: 8px;

        .ant-form-item-label {
            text-align: left;
        }
    }
    .sb-btn-section{
        margin-top: 15px;
    }

    .bed-checkbox .ant-form-item-control-input {
        border: none !important;
    }
}



@media (max-width:900px){
    .add-status-board-form {
        .sb-btn-section{
            padding:0 1rem 1rem 0;
        }
    }
}

@media (max-width:600px){
    .add-status-board-form {
        .sb-btn-section{
            padding:0 0.5rem 1rem 0;
        }
    }
}
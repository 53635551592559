.ant-switch-inner-checked {
  display: flex !important;
  align-items: center;
  svg {
    font-size: 1.2rem;
  }
}
.ant-switch-inner-unchecked {
  display: flex !important;
  align-items: center;
  svg {
    font-size: 1rem;
  }
}

.upload-video-container,
.upload-image-container {
  color: var(--sitespage-text-color);
  position: relative;
  .ant-upload-drag {
    height: 9.5rem !important;
    width: 100% !important;
  }
}

.preview-video-container,.image-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  video{
    width: 100%;
    border-radius: .5rem;
  }
  img{
    border-radius: .5rem;
  }
}

.upload-image-container {
  .ant-upload-drag {
    padding: 0 0.5rem;
  }
  .ant-tooltip {
    display: none !important;
  }
}

.video-label {
  color: var(--sitespage-text-color);
  margin: 1rem 0 0.5rem 0 !important;
  span {
    color: #ff4d4f; /* Change the color */
    font-weight: bold; /* Make it bold */
    margin-right:.35rem; /* Add some spacing between the text and the symbol */
  }
}
.upload-drag-icon {
  svg {
    font-size: 4rem;
  }
  color: var(--sitespage-text-color);
  margin: 0 !important;
}

.add-Help-container {
  .snapshot-upload-btn {
    background-color: #0179b8;
    display: flex;
    align-items: center;
    svg {
      font-size: 1.25rem;
    }
  }
  .ant-input {
    border: 1px solid var(--border-color-primary) !important;
  }
  .ant-input.ant-input-status-error {
    border: 1px solid #ff4d4f !important;
  }
  .url-text-area-container {
    .ant-form-item-explain-error {
      margin: 0 0 0 1.2rem !important;
    }
  }
  .ant-form-item-control-input {
    border: none !important;
  }
  .ant-input-number-input-wrap {
    input {
      background-color: var(--login-input-label-background-color);
      color: var(--change-password-form-input-color);
    }
  }
  .ant-input-number-handler-wrap {
    opacity: 1 !important;
    span {
      background-color: var(--custom-modal-background-color);
    }
  }
}

@import "../../assets/styles/main.scss";

.bed-report-gnt-container {
  padding: 0 2rem 2rem 2rem;

  .gnt-section-title-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    h2,
    .report-title {
      @extend %typo-medium-bold;
      color: var(--sitespage-text-color);
      font-size: 1.25rem;
      margin: 0.5rem 0 0 0;
      letter-spacing: 1px;
    }
    p {
      color: var(--sitespage-text-color);
      margin: 0.25rem 0 0 0;
      opacity: 0.9;
    }
  }

  .duration-and-unit-container {
    p {
      color: var(--sitespage-text-color);
      margin: 0.25rem 0 0 0;
      text-align: left;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // width: 300px;
    }
  }

  .duration-and-unit-container-expanded {
    p {
      color: var(--sitespage-text-color);
      margin: 0.25rem 0 0 0;
      text-align: left;
    }
  }

  .gnt-section-icon-container {
    display: flex;

    align-items: center;
    .icon svg {
      width: 3.5rem;
      height: 3.5rem;
    }
    p {
      @extend %typo-medium-bold;
      color: var(--sitespage-text-color);
      font-size: 1.25rem;
      margin: 0 0.5rem 0 0;
      letter-spacing: 1px;
    }
  }

  .report-title {
    @extend %typo-medium-bold;
    color: var(--sitespage-text-color);
    font-size: 1.45rem;
    margin: 1rem 0 0 0;
    letter-spacing: 1px;
  }

  .report-preview-footer {
    margin: 5rem 0 0 0;
    p {
      color: var(--sitespage-text-color);
      font-size: 0.65rem;
      letter-spacing: 1px;
      margin: 0.3rem 0 0 0;
      letter-spacing: 1px;
      opacity: 0.9;
    }
  }
}

.bed-report-gnt-container.pdf-generated {
  p,
  h2 {
    color: #111111;
  }
}

.ml-1 {
  margin-left: 0.5rem;
}

.data-text-style {
  color: var(--sitespage-text-color);
  font-size: 0.95rem;
  letter-spacing: 1px;
  letter-spacing: 1px;
  text-align: left;
}

.data-title-style {
  @extend %typo-medium-bold;
  color: var(--sitespage-text-color);
  font-size: 0.95rem;
  margin: 0 0.5rem 0 0;
  letter-spacing: 1px;
}

.align-center {
  display: felx;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.align-center-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  .ant-picker-dropdown-range {
    .ant-picker-panel:last-child .ant-picker-date-panel {
      display: none !important;
    }
    .ant-picker-panel:first-child
      .ant-picker-date-panel
      .ant-picker-header-next-btn,
    .ant-picker-panel:first-child
      .ant-picker-date-panel
      .ant-picker-header-super-next-btn {
      visibility: initial !important;
    }

    .ant-picker-panel.ant-picker-range-wrapper {
      min-width: 288px !important;
    }

    .ant-picker-presets {
      display: none !important;
    }
  }
}

.video-and-action-container {
    height: fit-content;
    background-color: var(--modal-background-color);
    border-radius: 0.75rem;
    border: 2px solid rgb(233, 233, 233);
    .skeleton-loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 10rem !important;
    }
    .sort-btn-container{
      position: absolute;
      top: .25rem;
      left: .5rem;
      svg{
        font-size: 1.5rem;
      }
    }
    .control-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1rem;
      justify-content: space-between;
      .view-toggle-btn-container{
        span{
          color: var(--sitespage-text-color);
        }
      }
      .video-update-actions-container{
        display: flex;
        flex-direction: row;
      }
      .active-status-section,
      .visibility-section,
      .edit-section,
      .delete-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        .active-symbol {
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
        }
        p {
          margin: 0 0 0.25rem 0.25rem !important;
          font-size: 0.75rem;
        }
        .active-symbol + p{
          margin: 0 0 0 0.25rem !important;
        }
        .visibility-icon{
          font-size: 1.25rem;
        }
        .edit-icon {
          color: #00a5b4;
          font-size: 1.25rem;
        }
        .delete-icon {
          color: #e62020;
          font-size: 1.25rem;
        }
      }
      .edit-section,
      .delete-section{
        cursor: pointer;
      }
      .ant-switch {
        background-color: var(--homepage-content-container-color) !important;
      }
      .ant-switch-checked {
        background-color: #797979 !important;
        span{
          color: #f1f1f1;
        }
     }
     .visibility-section{
      p{
        font-size: .9rem;
      }
     }
     .delete-section{
      margin: 0 0 0 .5rem;
    }
    }
  }
  .video-container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    height: fit-content;
    video {
      border-radius: 0.75rem;
      width: 100%;
    }
    .video-controls-container {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.603);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem;
    }
    .video-selection-box-container {
      position: absolute;
      right: 0.5rem;
      top: 0.25rem;
      .ant-checkbox-inner {
        background-color: transparent !important;
      }
      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: rgb(6, 194, 252) !important;
        }
      }
    }
    .play-and-pause-icon-container {
      position: absolute;
      cursor: pointer;
      svg {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
    
    .video-titile {
      position: absolute;
      margin: 5rem 0 0 0;
      font-size: 0.8rem;
      font-weight: 600;
    }
    .duration-time {
      position: absolute;
      bottom: 0.75rem;
      right: 0.5rem;
      font-size: 0.75rem;
    }
    .progress {
      position: absolute;
      width: 95%;
      height: 0.19rem;
      background-color: rgb(255, 255, 255);
      bottom: 1.25rem;
      border-radius: 0.25rem;
      cursor: pointer;
      .progress-bar {
        position: absolute;
        border-radius: 0.25rem;
        height: 100%;
        background-color: #0179b8;
        transition: all 0.2s ease-in-out;
      }
    }
    .fullScreen {
      position: absolute;
      bottom: 0;
      right: 0.5rem;
      cursor: pointer;
    }
    .picture-in-picture {
      position: absolute;
      bottom: 0;
      right: 2rem;
      cursor: pointer;
      width: 1.1rem;
      height: 1.1rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .volume-controls {
      display: flex;
      position: absolute;
      bottom: -0.5rem;
      right: 3.25rem;
      align-items: center;
      justify-content: center;
      .mute-icon {
        cursor: pointer;
      }
      .volume-slider {
        width: 0;
        transform-origin: left;
        transform: scaleX(0);
        transition: width 150ms ease-in-out, transform 150ms ease-in-out;
        .ant-slider-handle {
          height: 0;
        }
      }
      &:hover .volume-slider,
      .volume-slider:focus-within {
        width: 50px;
        transform: scaleX(1);
      }
    }
  }
//--------- Font family -----------//

body {
  --font-family: "Montserrat-Medium", sans-serif !important;
  font-size: 16px;
}

//---------- PARAGRAPH ------------//

p {
  font-size: 1em;
}

%typo-customer-details {
  font-size: 17px;
  font-weight: 300;
}

//----------------------------- TITLE -----------------------------//

%typo-title {
  font-size: 1em;
  font-weight: 300;
}

%typo-login-title {
  font-size: 18px;
  font-weight: 500;
}

%typo-tooltip {
  font-size: 12px;
  font-weight: 500;
}

//----------------------------- ICONS -----------------------------//

%typo-icon-primary {
  font-size: 1em;
}

%typo-icon-secondary {
  font-size: 15px;
}

//----------------------------- DEFAULT (16px) -----------------------------//

%typo-default-normal {
  font-size: 1em;
  font-weight: 400;
}

%typo-default-medium {
  font-size: 1em;
  font-weight: 500;
}

%typo-default-semibold {
  font-size: 1em;
  font-weight: 600;
}

%typo-default-bold {
  font-size: 1em;
  font-weight: 700;
}

%typo-default-light {
  font-size: 1em;
  font-weight: 300;
}

//----------------------------- TINY (10px) -----------------------------//

%typo-tiny-normal {
  font-size: 0.63em;
  font-weight: 400;
}

%typo-tiny-medium {
  font-size: 0.63em;
  font-weight: 500;
}

%typo-tiny-semibold {
  font-size: 0.63em;
  font-weight: 600;
}

//----------------------------- XX-SMALL(12px) -----------------------------//

%typo-xxsmall-normal {
  font-size: 0.75em;
  font-weight: 400;
}

%typo-xxsmall-medium {
  font-size: 0.75em;
  font-weight: 500;
}

%typo-xxsmall-semibold {
  font-size: 0.75em;
  font-weight: 600;
}

%typo-xxsmall-bold {
  font-size: 0.75em;
  font-weight: 700;
}

//----------------------------- X-SMALL(13px) -----------------------------//

%typo-xsmall-normal {
  font-size: 0.81em;
  font-weight: 400;
}

%typo-xsmall-medium {
  font-size: 0.81em;
  font-weight: 500;
}

%typo-xsmall-semibold {
  font-size: 0.81em;
  font-weight: 600;
}

%typo-xsmall-bold {
  font-size: 0.81em;
  font-weight: 700;
}

//----------------------------- SMALL(14px) -----------------------------//

%typo-small-normal {
  font-size: 0.87em;
  font-weight: 400;
}

%typo-small-medium {
  font-size: 0.875em;
  font-weight: 500;
}

%typo-small-semibold {
  font-size: 0.875em;
  font-weight: 600;
}

%typo-small-bold {
  font-size: 0.875em;
  font-weight: 700;
}

//----------------------------- X-MEDIUM(15px)-----------------------------//

%typo-xmedium-light {
  font-size: 0.94em;
  font-weight: 300;
}

%typo-xmedium-normal {
  font-size: 0.94em;
  font-weight: 400;
}

%typo-xmedium-medium {
  font-size: 0.94em;
  font-weight: 500;
}

%typo-xmedium-semibold {
  font-size: 0.94em;
  font-weight: 600;
}

%typo-xmedium-bold {
  font-size: 0.94em;
  font-weight: 700;
}

//----------------------------- MEDIUM(17px) -----------------------------//

%typo-medium-normal {
  font-size: 1.06em;
  font-weight: 400;
}

%typo-medium-medium {
  font-size: 1.06em;
  font-weight: 500;
}

%typo-medium-semibold {
  font-size: 1.06em;
  font-weight: 600;
}

%typo-medium-bold {
  font-size: 1.06em;
  font-weight: 700;
}

%typo-medium-light {
  font-size: 1.06em;
  font-weight: 300;
}

//----------------------------- SEMI-MEDIUM(18px) -----------------------------//

%typo-semi-medium-normal {
  font-size: 1.13em;
  font-weight: 400;
}

%typo-semi-medium-medium {
  font-size: 1.13em;
  font-weight: 500;
}

%typo-semi-medium-semibold {
  font-size: 1.13em;
  font-weight: 600;
}

%typo-semi-medium-bold {
  font-size: 1.13em;
  font-weight: 700;
}

%typo-semi-medium-light {
  font-size: 1.13em;
  font-weight: 300;
}

//----------------------------- BIG(19px) -----------------------------//

%typo-big-normal {
  font-size: 1.2em;
  font-weight: 400;
}

%typo-big-medium {
  font-size: 1.2em;
  font-weight: 500;
}

%typo-big-semibold {
  font-size: 1.2em;
  font-weight: 600;
}

%typo-big-bold {
  font-size: 1.2em;
  font-weight: 700;
}

%typo-big-light {
  font-size: 1.2em;
  font-weight: 300;
}

//----------------------------- LARGE(20px) -----------------------------//

%typo-large-normal {
  font-size: 1.45em;
  font-weight: 400;
}

%typo-large-medium {
  font-size: 1.45em;
  font-weight: 500;
}

%typo-large-semibold {
  font-size: 1.45em;
  font-weight: 600;
}

%typo-large-bold {
  font-size: 1.45em;
  font-weight: 700;
}

%typo-large-light {
  font-size: 1.45em;
  font-weight: 300;
}

//----------------------------- EXTRA LARGE(22px) -----------------------------//

%typo-xlarge-normal {
  font-size: 2.19em;
  font-weight: 400;
}

%typo-xlarge-medium {
  font-size: 2.19em;
  font-weight: 500;
}

%typo-xlarge-semibold {
  font-size: 2.19em;
  font-weight: 600;
}

%typo-xlarge-bold {
  font-size: 2.19em;
  font-weight: 700;
}

%typo-xlarge-light {
  font-size: 2.19em;
  font-weight: 300;
}

//----------------------------- HUGE (25px) -----------------------------//

%typo-huge-normal {
  font-size: 2.6em;
  font-weight: 400;
}

%typo-huge-medium {
  font-size: 2.6em;
  font-weight: 500;
}

%typo-huge-semibold {
  font-size: 2.6em;
  font-weight: 600;
}

%typo-huge-bold {
  font-size: 2.6em;
  font-weight: 700;
}

%typo-huge-light {
  font-size: 2.6em;
  font-weight: 300;
}

//----------------------------- X-HUGE (28px) -----------------------------//

%typo-xhuge-normal {
  font-size: 3em;
  font-weight: 400;
}

%typo-xhuge-medium {
  font-size: 3em;
  font-weight: 500;
}

%typo-xhuge-semibold {
  font-size: 3em;
  font-weight: 600;
}

%typo-xhuge-bold {
  font-size: 3em;
  font-weight: 700;
}

%typo-xhuge-light {
  font-size: 3em;
  font-weight: 300;
}

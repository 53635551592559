@import "../../assets/styles/main.scss";

.report-managment-cards-container {
  width: 100%;
  height: 100%;
  padding: 5rem 0 5rem 10%;
  overflow-x: hidden;

  .report-managment-card-container {
    height: 15vh;
    border-radius: 11px;
    border: 0.5px solid var(--homepage-card-border-color);
    box-shadow: 5px 5px 5px var(--homepage-card-box-shadow-color);
    background-color: var(--homepage-card-background-color);
    font-family: "Montserrat", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;

    .ant-card-body {
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
      padding: 0 2rem;
    }

    .report-managment-card-content {
      @extend %typo-semi-medium-semibold;
      color: var(--homepage-card-text-color);
      margin-top: 8%;
      flex: 1;
      margin-left: 10px;
      text-align: left;
    }

    // Apply styles when card is disabled
    &.disabled {
      cursor: not-allowed;
    }

    // Apply hover effect only when the card is not disabled
    &:not(.disabled):hover {
      box-shadow: 0 0 5px 5px var(--homepage-card-border-color) !important;
    }

    // &:hover {
    //   box-shadow: 0 0 5px 5px var(--homepage-card-border-color) !important;
    // }
  }
}

@media (max-width: 900px) {
  .report-managment-cards-container {
    .report-managment-card-container {
      height: 15vh;

      .ant-card-body {
        padding: 10% 5%;
      }
    }
  }
}

@media (max-width: 578px) {
  .report-managment-cards-container {
    .report-managment-card-container {
      .ant-card-body {
        padding: 10% 0 10% 15%;
      }
    }
  }
}

@media (max-height: 578px) {
  .report-managment-cards-container {
    padding: 1rem 0 2rem 0;

    .report-managment-card-container {
      .ant-card-body {
        padding: 2rem 2rem;
      }
    }
  }
}
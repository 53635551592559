.ant-pagination-item-active {
    background-color: #21c278 !important;
}

.ant-pagination-item {
    background-color: green;
}


.table-row-light {
    background-color: var(--sitespage-table-nth-row-text-color);
}

.table-row-dark {
    background-color: var(--sitespage-table-nthplusone-row-text-color);
}

.ant-table-measure-row {
    display: none;
}

.ant-table-thead .ant-table-cell {
    background-color: var(--sitespage-table-header-color) !important;
    color: var(--common-black-white) !important;
}

.ant-table {
    color: var(--common-black-white);
}

.ant-table-wrapper .ant-table {
    color: var(--common-black-white) !important;
}

.ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-thead>tr>td {
    color: var(--common-black-white);
}

.ant-table-wrapper .ant-table-tbody>tr.ant-table-row:hover>th,
.ant-table-wrapper .ant-table-tbody>tr.ant-table-row:hover>th,
.ant-table-wrapper .ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-wrapper .ant-table-tbody>tr>th.ant-table-cell-row-hover,
.ant-table-wrapper .ant-table-tbody>tr>td.ant-table-cell-row-hover {
    background-color: var(--input-label-font-color);
}

.ant-pagination .ant-pagination-item-active {
    border-color: transparent;
}

:where(.css-dev-only-do-not-override-15sdiyk).ant-pagination .ant-pagination-item-active a {
    color: #fff;
}

.ant-pagination .ant-pagination-prev button,
.ant-pagination .ant-pagination-next button {
    color: var(--common-black-white);
}

.ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>th,
.ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>td {
    background-color: transparent;
}
.add-leave-container {
  //   .ant-modal .ant-modal-footer {
  //     margin: 0.9rem;
  //   }
}

.ant-modal .ant-modal-footer {
  margin: 0.9rem;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input):hover .ant-select-selector {
  border-color: transparent !important;
}
@import "../../assets/styles/main.scss";

.ems-rpt-management-container {
  width: 100%;
  overflow: hidden;
  padding: 2rem 0rem 2rem 1rem;
  .ant-select {
    width: 100% !important;
    text-align: left;
  }

  .gnt-section-title-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    h2,
    .report-title {
      @extend %typo-medium-bold;
      color: var(--sitespage-text-color);
      font-size: 1.25rem;
      margin: 0.5rem 0 0 0;
      letter-spacing: 1px;
    }
    p {
      color: var(--sitespage-text-color);
      margin: 0.25rem 0 0 0;
      opacity: 0.9;
    }
  }

  .ant-radio-group {
    text-align: left;
    margin-left: 0.5rem;
  }
  .ant-picker {
    background-color: var(--selection-bar-background-color) !important;
  }

  .form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .ant-form-item {
    width: 100%;
    .ant-form-item-explain-error {
      text-align: right;
    }
  }
  .generate-section-title {
    @extend %typo-medium-semibold;
    color: var(--sitespage-text-color);
    font-size: 1.15rem;
    margin: 0.5rem 0 0 0;
  }
  .generate-report-button {
    background-color: #21c278;
    border-color: #21c278;
    color: #fff;
    font-size: 14px;
    &:hover,
    &:focus {
      background-color: #21c278;
      border-color: #21c278 !important;
      color: #fff !important;
      font-size: 14px;
    }
  }
  
}

.ant-picker-time-panel-cell-inner {
  color: var(--sitespage-text-color) !important;
}

.ant-picker-clear{
  span{
    background-color: var(--selection-bar-background-color) !important;
  }
}

.align-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.text-label-style {
  @extend %typo-medium-semibold;
  color: var(--sitespage-text-color);
}

.mb-1 {
  margin-bottom: 0.3rem;
}
.mb-2 {
  margin-bottom: 0.6rem;
}

.required::after {
  content: " *";
  font-weight: 100;
  left: 0;
  color: red;
  position: absolute;
}

.empty-message-popup-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  svg{
    height: 2.6rem;
    width: 2.25rem; 
  }
  p{
    margin: .5rem 0 0 0;
    font-size: .95rem;
  }
}

@media (max-width:900px){
  .generate-report-button{
    margin: 1rem 0 0 0;
  }
}


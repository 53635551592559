.simple-button {
  &.primary {
    background-color: #21c278;
    border-color: #21c278;
    color: #fff;
    font-size: 14px;

    &:hover,
    &:focus {
      background-color: #21c278;
      border-color: #21c278 !important;
      color: #fff !important;
      font-size: 14px;
    }
  }

  &.secondary {
    background-color: #00a5b4;
    border-color: #00a5b4;
    color: #fff;
    font-size: 14px;

    &:hover,
    &:focus {
      background-color: #00a5b4;
      border-color: #00a5b4 !important;
      color: #fff !important;
      font-size: 14px;
    }

    &:disabled {
      opacity: 0.25;
    }
  }

  &.tertiary {
    background-color: #686868;
    border-color: #686868;
    color: #fff;
    font-size: 14px;

    &:hover,
    &:focus {
      background-color: #686868;
      border-color: #686868 !important;
      color: #fff !important;
      font-size: 14px;
    }
  }

  &.calm {
    background-color: #1181e9;
    border-color: #ffffff;
    color: #fff;
    font-size: 14px;

    &:hover,
    &:focus {
      background-color: #1181e9b6;
      border-color: #ffffff !important;
      color: #fff !important;
      font-size: 14px;
    }
  }

  &.danger {
    background-color: #e62020;
    border-color: #e62020;
    color: #fff;
    font-size: 14px;

    &:hover,
    &:focus {
      background-color: #e62020;
      border-color: #e62020 !important;
      color: #fff !important;
      font-size: 14px;
    }
  }

  &.danger-default {
    background-color: transparent;
    border: 1px solid #e62020 !important;
    color: #e62020 !important;
    font-size: 14px;
    font-weight: 500;

    &:hover,
    &:focus {
      background-color: transparent;
      border: 1px solid #e62020 !important;
      color: #e62020 !important;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &.danger-disabled {
    background-color: transparent;
    border: 1px solid #e62020 !important;
    color: #e62020 !important;
    font-size: 14px;
    opacity: 0.7;
    font-weight: 500;

    &:hover,
    &:focus {
      background-color: transparent;
      border: 1px solid #e62020 !important;
      color: #e62020 !important;
      font-size: 14px;
      opacity: 0.7;
      font-weight: 500;
    }
  }
}


@media (max-width:900px){
  .simple-button{
    &.danger-disabled{
      opacity: 0.3;
      &:hover,
    &:focus {
      opacity: 0.3;
    }
    }
  }
}

@media all and (max-width: 1024px) and (max-height: 768px) and (orientation:landscape){
  .simple-button{
    &.danger-disabled{
      opacity: 0.3;
      &:hover,
    &:focus {
      opacity: 0.3;
    }
    }
  }
}
.add-public-holiday-form {
  margin-top: 10px;

  .ant-form-item {
    margin-bottom: 8px;

    .ant-form-item-label {
      text-align: left;
    }
  }

  .ct-btn-section {
    margin-top: 15px;
  }

  .schedule-checkbox .ant-form-item-control-input {
    border: none !important;
  }
  .css-dev-only-do-not-override-1l7kcvh.ant-picker:not(.ant-picker-disabled).ant-picker-status-error {
    background-color: transparent !important;
  }
}

.ant-select-dropdown .ant-select-item-option-state {
  display: none;
}

@media (max-width: 900px) {
  .add-public-holiday-form {
    .ct-btn-section {
      padding: 0 1rem 1rem 0;
    }
  }
}

@media (max-width: 600px) {
  .add-public-holiday-form {
    .ct-btn-section {
      padding: 0 0.5rem 1rem 0;
    }
  }
}

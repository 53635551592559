@import "../../assets/styles/main.scss";

.schedule-report-container{
    width: 100%;
    padding: 1.5rem 1rem 2rem 1rem;
}

.align-left{
    display: flex;
    justify-content: flex-start;
}
.align-right{
    display: flex;
    justify-content: flex-end;
}


.dashboard-table-container {
  width: 100%;
  padding: 2rem 1rem 2rem 1rem;
  .statusBoard-header {
    text-align: left;
    margin: 1.5rem 0 0 0;
    font-size: 1rem;
    font-weight: 600;
    color: var(--sitespage-text-color);
  }
  .group-filter-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    label {
      margin: 0 0.5rem 0 0;
      font-size: 1.2em;
      color: var(--sitespage-text-color);
      font-weight: bold;
    }
  }
  textarea {
    border: 1px solid var(--sitespage-text-color) !important;
  }
  .dashboard-send-message-text {
    color: var(--sitespage-text-color);
  }

  .oncall-status-symbol{
    width: .75rem;
    height: .75rem;
    border-radius: 50%;
    margin: 0 0 0 40%;
  }

  .dashboard-send-btn-wrapper {
    margin: 0 0 0 1rem;
    button {
      background-color: #21c278;
      color: white !important;
      &:hover{
        color: white !important;
      }
    }
  }
}

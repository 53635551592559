.add-status-board-column-form {
  margin-top: 10px;
  .ant-form-item {
    margin-bottom: 8px;
    .ant-form-item-label {
      text-align: left;
    }
  }
  .sbc-btn-section {
    margin-top: 15px;
  }
  .value-input-container {
    label {
      margin-left: 0.4rem;
    }
  }
}

@media (max-width:900px){
  .add-status-board-column-form {
      .sbc-btn-section{
          padding:0 1rem 1rem 0;
      }
  }
}

@media (max-width:600px){
  .add-status-board-column-form {
      .sbc-btn-section{
          padding:0 0.5rem 1rem 0;
      }
  }
}
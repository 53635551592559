@import "../variables/variable-light-theme";
@import "../variables/variable-dark-theme";
@import "../fonts/typography.scss";
@import "../fonts/colors.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
 overflow: hidden;
}

/* Extra Small Screens (Up to 576px) */
@media (max-width: 576px) {
  .hide-on-xs-screen {
    display: none;
  }
}

/* Small Screens (577px to 768px) */
@media (min-width: 577px) and (max-width: 768px) {
  .hide-on-sm-screen {
    display: none;
  }
}

/* Medium Screens (769px to 992px) */
@media (min-width: 769px) and (max-width: 992px) {
  .hide-on-md-screen {
    display: none;
  }
}

/* Large Screens (993px to 1200px) */
@media (min-width: 993px) and (max-width: 1200px) {
  .hide-on-lg-screen {
    display: none;
  }
}

/* Extra Large Screens (1201px and above) */
@media (min-width: 1201px) {
  .hide-on-xl-screen {
    display: none;
  }
}

@import "../../../../assets/styles/main.scss";

.criteria-card-container {
  width: 100%;
  height: 100%;
  //   padding: 5rem 0 5rem 10%;
  .criteria-card {
    min-height: 15vh;
    border-radius: 11px;
    border: 0.5px solid #21c278;
    background-color: var(--selection-bar-background-color);
    font-family: "Montserrat", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .ant-card-body {
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding: .75rem .75rem;
      .criteria-card-title {
        @extend %typo-semi-medium-semibold;
        color: var(--homepage-card-text-color);
        font-size: .95rem;
      }
      .criteria-card-content {
        @extend %typo-semi-medium-semibold;
        color: var(--homepage-card-text-color);
        margin: 0 0 0 0;
        font-size: 0.9rem;
      }
    }
  }
}


.add-care-team-form {
  margin-top: 10px;

  .ant-form-item {
    margin-bottom: 8px;

    .ant-form-item-label {
      text-align: left;
    }
  }

  .ct-btn-section {
    margin-top: 15px;
  }

  .schedule-checkbox .ant-form-item-control-input {
    border: none !important;
  }
}

.ant-select-dropdown .ant-select-item-option-state {
  display: none;
}

@media (max-width:900px){
  .add-care-team-form {
      .ct-btn-section{
          padding:0 1rem 1rem 0;
      }
  }
}

@media (max-width:600px){
  .add-care-team-form {
      .ct-btn-section{
          padding:0 0.5rem 1rem 0;
      }
  }
}
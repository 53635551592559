.assign-nurse-form {
    margin-top: 10px;

    .ant-form-item {
        margin-bottom: 8px;

        .ant-form-item-label {
            text-align: left;
        }

    }
    .ant-btn-default:disabled{
        opacity: .3;
    }
}
@import "../../assets/styles/main.scss";

.assign-nurse-container {
    font-family: var(--font-family);
    text-align: left;
    margin-bottom: 10px;
    width: 100%;
    padding: 10px 15px 2rem 15px;
    font-size: 16px !important;

    .dropdown-button .ant-space {
        gap: 80px !important;
        color: var(--custom-modal-title-color);
    }

    .dropdown-button {
        background-color: var(--modal-background-color) !important;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .assign-nurse-header {
        @extend %typo-small-bold;
        color: var(--sitespage-text-color);
        margin-bottom: 10px;
        font-size: 1em;
    }

    .add-assign-nurse-icon {
        color: var(--sitespage-search-icon-text-color);
    }

    .search-bar {
        .ant-input-affix-wrapper {
            border-style: none;
        }
    }

    .add-assign-nurse-sec {
        text-align: right;
    }

    .add-Care-team-icon {
        color: #fff;
        font-size: 15px;
    }

    .action-text {
        @extend %typo-small-semibold;
        color: var(--common-black-white);
    }



}

.popover-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.popover-header h4 {
    margin: 0;
    font-weight: bold;
    font-size: 1em;
}

.label-text {
    margin-bottom: 10px;
    font-size: 1em;
    font-weight: bold;
}

.popover-item {
    margin-bottom: 20px;
}

.filter-button.ant-btn-default {
    background-color: var(--modal-background-color) !important;
    border: 1px solid var(--border-color-primary) !important;
    color: var(--sitespage-text-color) !important;
}

.filter-button:hover {
    background-color: rgb(90, 87, 87) !important;
}

@media (max-width:900px){
    .an-filter-modal {
        .ant-modal-footer {
          padding: 0 1rem 1rem 0 !important;
        }
    }
}

@media (max-width:600px){
    .an-filter-modal {
        .ant-modal-footer {
          padding: 0 1rem 1rem 0 !important;
        }
    }
}





.graph-wrapper{
    padding:0rem 1rem 0 3rem ;
    height: 600px;
    margin:3rem 0 0 0;
}

@media (max-width:900px){
    .graph-wrapper{
        padding:0rem 1rem 0 1rem ;
        height: 600px;
        margin:3rem 0 0 0;
    }
}

@media only screen and (min-width: 601px) and (max-width: 1200px){
    .graph-wrapper{
        padding:0rem 1rem 0 1rem ;
        height: 600px;
        margin:3rem 0 0 0;
    }
}
@import "../../../../assets/styles/main.scss";

.change-info-container{
    p{
        font-size: 70%;
        margin-bottom: 0 !important;
    }
    .title-style{
        @extend %typo-medium-bold;
        color: var(--sitespage-text-color);
        font-size: 100%;
        margin: 0 0 .6rem 0 !important;
    }
    .sub-title-style{
        @extend %typo-medium-semibold;
        color: var(--sitespage-text-color);
        font-size: 75%;
    }
}

.align-center{
    display: flex;
    align-items: center;
    // justify-content: center;
}

.align-right{
    display: flex;
    align-items: flex-end;
    // justify-content: center;
}

.onCall-count-header-wrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    h2{
        font-size: .95rem;
        font-weight: 600;
        margin: 0 !important;
    }
    .onCall-count-header-box{
        border: 2px solid #21c278;
        border-radius: .35rem;
        padding: .25rem;
        margin: 0 0 0 2rem;
        p{
            margin: 0 !important;
            font-size: .5rem;
        }
        p+p{
            font-size: 1rem;
            font-weight: 700;
        }
    }
}
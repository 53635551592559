@import "../styles/theme/theme.scss";

body {
    background-color: var(--background-color) !important;
}

.spinner-container {
    background-color: var(--background-color) !important;
}

.ant-divider {
    border-block-start: 1px solid var(--common-divider-color);
}

.ant-input-affix-wrapper {
    background-color: var(--modal-background-color);
}

.ant-select-selector {
    color: var(--change-password-form-input-color) !important;

    .ant-select-selection-item {
        font-weight: 600;
    }
}


.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-form-item-control-input,
.ant-picker,
.ant-tag,
.ant-card {
    border: 1px solid var(--border-color-primary);
}

.ant-form-item .ant-form-item-label label {
    font-weight: 500;
}

.ant-input {
    background-color: var(--login-input-label-background-color);
    font-weight: 600;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.ant-form-item-control-input,
.ant-card-body {
    border-radius: 8px !important;
}

.ant-table-wrapper .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: none;
}

.ant-table-wrapper .ant-table-tbody>tr>td,
.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-thead>tr>td {
    border-bottom: 1px solid var(--border-color-primary) !important;
}

.ant-pagination-item-ellipsis {
    color: var(--common-black-white) !important;
}

.anticon-double-right svg,
.anticon-double-left svg {
    fill: var(--common-black-white);
}

.ant-pagination .ant-pagination-item-active {
    color: var(--custom-modal-title-color) !important;
    background-color: var(--modal-background-color);
}

.ant-input-affix-wrapper>input.ant-input {
    // background: none;
    background-color: var(--modal-background-color);
    opacity: 1;
}

.ant-picker-input>input::placeholder,
.ant-select-selection-placeholder {
    color: var(--custom-modal-title-color) !important;
    opacity: 0.50;
}

.ant-input::placeholder {
    color: var(--custom-modal-title-color) !important;
    opacity: 0.50;
}

.ant-input-prefix {
    color: var(--custom-modal-title-color) !important;
}


.ant-layout {
    background: none;
}

.ant-tag {
    color: var(--common-black-white);
    background-color: var(--homepage-popover-background-color);
}

.ant-tag-close-icon {
    color: var(--common-black-white) !important;
}


.table-row-light {
    background-color: var(--sitespage-table-nth-row-text-color);
}

.table-row-dark {
    background-color: var(--sitespage-table-nthplusone-row-text-color);
}

.ant-empty-description {
    color: var(--common-black-white) !important;
}


.ant-table-wrapper .ant-table-tbody>tr.ant-table-row:hover>th,
.ant-table-wrapper .ant-table-tbody>tr.ant-table-row:hover>th,
.ant-table-wrapper .ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-wrapper .ant-table-tbody>tr>th.ant-table-cell-row-hover,
.ant-table-wrapper .ant-table-tbody>tr>td.ant-table-cell-row-hover {
    background-color: var(--homepage-card-box-shadow-color);
}

.ant-table-wrapper .ant-table-tbody>tr>td:hover {
    background-color: var(--homepage-card-box-shadow-color) !important;
}

.ant-table-wrapper .ant-table-thead>tr>th:hover {
    background-color: none;
}

.ant-table-cell {
    color: var(--common-black-white) !important;
}

.ant-picker {
    background-color: var(--homepage-popover-background-color);
    width: 100%;

    .ant-picker-input>input {
        color: var(--common-black-white) !important;
    }
}

.ant-picker-panel-container {
    background-color: var(--homepage-popover-background-color) !important;
}

.ant-picker-header,
.ant-picker-dropdown .ant-picker-content th,
.ant-picker-dropdown .ant-picker-header button,
.ant-picker-dropdown .ant-picker-cell-in-view,
.ant-picker-separator,
.ant-picker-suffix,
.ant-modal-close {
    color: var(--common-black-white) !important;
}


.ant-picker-dropdown .ant-picker-cell {
    color: invert($color: #898787);

}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: none;
}

.popover-header .ant-btn-default {
    background-color: var(--modal-background-color) !important;
    border: 1px solid var(--border-color-primary) !important;
    color: var(--sitespage-text-color) !important;
}

.ant-popover .ant-popover-inner-content {
    color: var(--common-black-white);
}

.ant-popover .ant-popover-title {
    color: var(--common-black-white);
}

.ant-popover .ant-popover-inner {
    background-color: var(--homepage-popover-background-color);
}

.ant-checkbox-wrapper {
    color: var(--common-black-white);
}

.ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>td {
    background: var(--change-password-form-cancel-btn-color);
}

.ant-dropdown {
    .ant-dropdown-menu {
        background-color: var(--homepage-popover-background-color);
        border: 1px solid var(--border-color-primary) !important;

        .ant-dropdown-menu-item {
            color: var(--common-black-white);
        }

        .ant-dropdown-menu-item:hover {
            background-color: var(--homepage-card-border-color);
        }

        .ant-dropdown-menu-item-disabled {
            opacity: 0.5;
        }

        .ant-dropdown-menu-item-disabled:hover {
            opacity: 1;
            color: var(--common-black-white);
        }
    }
}

.dropdown-button,
.ant-popover-inner {
    border: 1px solid var(--border-color-primary) !important;
}

.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    border: none;
    background: var(--homepage-popover-background-color);
}

.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-thead>tr>td,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--selection-bar-background-color) !important;
    color: var(--common-black-white);
}

.ant-table-wrapper .ant-table,
.ant-table-wrapper .ant-table-tbody>tr.ant-table-placeholder {
    background-color: var(--homepage-popover-background-color);
    color: var(--common-black-white);
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
    border-start-start-radius: 0;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
    border-start-end-radius: 0px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
    border-color: var(--common-black-white) !important;
}

.ant-select-arrow,
.ant-select-item {
    color: var(--common-black-white) !important;
}

.rc-virtual-list,
.ant-select-dropdown {
    background-color: var(--homepage-popover-background-color);
    color: var(--common-black-white) !important;

}

.ant-select-dropdown {
    border: 1px solid var(--border-color-primary) !important;
}

.ant-select-item-option-content:hover {
    opacity: 0.5;
}

.ant-select-item-option-active {
    opacity: 1 !important;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: var(--common-black-white) !important;
    background-color: var(--homepage-popover-background-color);
}

.add-user-card-container .ant-color-picker-trigger {
    background-color: var(--homepage-popover-background-color);
}

.ant-select-single.ant-select-lg.ant-select-show-arrow .ant-select-selection-placeholder {
    color: var(--common-black-white);
    opacity: 0.45;
}

.anticon svg,
.country-container .anticon-plus svg {
    color: var(--common-black-white) !important;
}

.ant-btn-default:hover {
    color: #fff !important;
}

.ant-popover-inner-content {
    .anticon-calendar svg {
        color: var(--common-black-white);
    }

    .ant-picker-clear {
        display: none;
    }
}

.ant-list-header,
.ant-radio-wrapper,
.ant-list-item,
.ant-space-item {
    color: var(--common-black-white) !important;
}

.ant-table-wrapper .ant-table-row-expand-icon {
    color: #000;
    border: 1px solid var(--border-color-primary) !important;
    border-radius: 3px;
}


.anticon-link svg,
.anticon-plus svg,
.anticon-book svg,
.anticon-download svg,
.anticon-cloud-upload svg,
.anticon-calendar svg {
    color: #fff !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1677ff !important;
    border-color: #1677ff !important;
    color: #fff !important;
}

.ant-checkbox-wrapper-disabled .ant-checkbox-disabled {
    background-color: var(--homepage-popover-background-color);
    color: var(--common-black-white) !important;
    border: 1px solid var(--border-color-primary) !important;

}

.ant-table-wrapper td.ant-table-column-sort,
.ant-table-wrapper .ant-table-thead th.ant-table-column-sort,
.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
    background-color: inherit;
}

h3 {
    color: var(--common-black-white) !important;
}

.checkbox-default .ant-checkbox-inner,
.ant-checkbox .ant-checkbox-inner,
.ant-radio-wrapper .ant-radio-inner {
    background-color: var(--homepage-popover-background-color);
    color: var(--common-black-white) !important;
    // border: 1px solid var(--border-color-primary) !important;

}

.ant-color-picker-presets-label {
    color: var(--common-black-white) !important;
}

.ant-divider-horizontal {
    margin: 12px 0;
}

.ant-modal-body {
    color: var(--common-black-white) !important;
}

.ant-modal-header {
    background-color: inherit;

    .ant-modal-title {
        color: var(--common-black-white) !important;
    }
}

.anticon-arrow-left svg,
.anticon-arrow-right svg,
.anticon-search svg,
.ant-pagination-item-link svg,
.anticon-reload svg {
    color: #fff !important;
}

.ant-checkbox span,
.ant-checkbox+span,
.ant-checkbox-disabled+span {
    color: var(--common-black-white) !important;
}

.ant-modal .ant-modal-header {
    background-color: inherit;
}

.ant-switch {
    background-color: #B4B5B7;
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background-color: #1677ff;
    color: #fff !important;
}

.ant-picker-dropdown .ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background-color: #1677ff;
    color: #fff !important;
}

.ant-tabs-tab {
    color: var(--common-black-white) !important;
}

@media (max-width: 576px) {
    .ant-popover-inner {
        width: 240px;
    }

    .table-row-light {
        font-size: 80%;
    }

    .table-row-dark {
        font-size: 80%;
    }
}
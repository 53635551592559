@import "../../assets/styles/main.scss";

.status-board-column-container {
  font-family: var(--font-family);
  text-align: left;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px 15px;
  font-size: 16px !important;
  .status-board-column-header-container {
    @extend %typo-small-bold;
    color: var(--sitespage-text-color);
    margin-bottom: 10px;
    font-size: 1em;
  }
  .action-text {
    @extend %typo-small-semibold;
    color: var(--common-black-white);
  }
  .dropdown-button {
    background-color: var(--modal-background-color) !important;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--custom-modal-title-color);
  }
  .add-statusBoard-column-button {
    text-align: right;
}
}


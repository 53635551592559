@import "../../assets/styles/main.scss";

.help-page-container {
  width: 100%;
  color: var(--sitespage-text-color);
  font-family: "Montserrat", sans-serif;
  padding: 1.5rem 1rem;
  h2 {
    font-weight: 700;
    font-size: 1.25rem;
    text-align: left;
  }
  .help-search-header {
    text-align: left;
    @extend %typo-small-bold;
    color: var(--sitespage-text-color);
    margin: 1rem 0 10px 0;
    font-size: 1em;
  }
  .ant-switch-inner-checked{
    display: flex !important;
    align-items: center;
    svg{
      font-size: 1.2rem;
    }
  }
  .ant-switch-inner-unchecked{
    display: flex !important;
    align-items: center;
    svg{
      font-size: 1rem;
    }
  }
  .filter-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .show-text{
      margin: 0 .5rem 0 0 !important;
    }
    .ant-switch {
      background-color: var(--modal-background-color) !important;
      span{
        color: var(--sitespage-text-color);
      }
    }
    .ant-switch-checked {
      background-color: #02b863 !important;
      span{
        color: #f1f1f1;
      }
  }
  }
  .help-video-virtual-list{
    width: 100%;
    .rc-virtual-list-holder-inner{
      flex-direction: row !important;
      flex-wrap: wrap;
      row-gap: 2rem;
    }
  }
}

@import "../../../assets//styles/main.scss";

.ant-tabs .ant-tabs-ink-bar {
    background: #21c278 !important;
}

.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
    color: var(--homepage-drawer-text-color);
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background-color: #00a5b4 !important;
}

.ant-divider.vertical-divider {
    background: var(--common-divider-color) !important;
}

.generalinfo-tab-content {
    color: var(--homepage-drawer-text-color);
}

.leave-days-content {
    color: var(--homepage-drawer-text-color);
}

.publicholiday-content {
    color: var(--homepage-drawer-text-color);
}

.mt-1 {
    margin-top: 8px;
}


.user-profile-detail {
    color: var(--homepage-drawer-text-color);
    font-family: "Montserrat", sans-serif;
    font-weight: 500;

    .profile-name {
        font-weight: bold;
        text-transform: uppercase;
    }
}

.usercard-next-button {
    .anticon svg {
        font-size: 2rem !important;
        color: rgb(2 184 99) !important;
    }

    background-color: transparent !important;
}

.user-card-section {
    max-height: 45vh;
    overflow-y: scroll;
    height: 45vh;

    .ant-table-wrapper .ant-table-tbody>tr>td {
        padding: 6px 6px !important;
    }

    .ant-table-wrapper .ant-table-thead>tr>th {
        background-color: var(--custom-modal-background-color) !important;
    }

    .ant-list-items .ant-list-item {
        padding: 6px 0px !important;
    }

    .ant-list .ant-list-item {
        padding: 6px 0px !important;
    }

    .ant-divider-horizontal {
        margin: 10px !important;
    }
}

@media (max-width: 768px) {
    .ant-modal-content {
        padding: 0.25rem 0.75rem 0.75rem 0.75rem !important;

        .ant-modal-close {
            top: 5px !important;
            right: 0.25rem !important;
        }
    }
}